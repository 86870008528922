import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useCurrentLang } from 'hooks/useCurrentLang';

import { Layout } from 'layout/Layout';
import NotFoundBlock from 'components/NotFoundBlock';
import findByKey from 'utils/findByKey';

import { INotFoundBlockResults } from './models';

import './style.scss';

/**
 * Private & public template
 */
const NotFoundPage = () => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();
  const data = useStaticQuery<INotFoundBlockResults>(graphql`
    query NotFoundPageQuery {
      allContentstackFinishNotFoundBlock {
        nodes {
          locale
          header {
            ...TypographyFragment
          }
          text {
            ...TypographyFragment
          }
          link {
            ...LinkFragment
          }
          seo {
            ...SeoFragment
          }
        }
      }
      allContentstackFinishFooter {
        nodes {
          locale
          ...FooterFragment
        }
      }
      allContentstackFinishHeader {
        nodes {
          locale
          ...HeaderFragment
        }
      }
      allContentstackFinishHeaderAccount {
        nodes {
          locale
          ...HeaderAccountFragment
        }
      }
      allContentstackFinishNavigationLoggedIn {
        nodes {
          locale
          ...HeaderNavigationLoggedInFragment
        }
      }
      allContentstackFinishFooterNavigationLoggedIn {
        nodes {
          locale
          ...FooterNavigationLoggedInFragment
        }
      }
    }
  `);

  const notFoundBlockByLang = findByKey(
    data.allContentstackFinishNotFoundBlock.nodes,
    'locale',
    currentLangCode
  );
  const footerByLang = findByKey(data.allContentstackFinishFooter.nodes, 'locale', currentLangCode);
  const headerByLang = findByKey(data.allContentstackFinishHeader.nodes, 'locale', currentLangCode);
  const headerAccountByLang = findByKey(
    data.allContentstackFinishHeaderAccount.nodes,
    'locale',
    currentLangCode
  );
  const headerNavigationLoggedInByLang = findByKey(
    data.allContentstackFinishNavigationLoggedIn.nodes,
    'locale',
    currentLangCode
  );
  const footerNavigationLoggedInByLang = findByKey(
    data.allContentstackFinishFooterNavigationLoggedIn.nodes,
    'locale',
    currentLangCode
  );

  return (
    <Layout
      className="not-found-page"
      data-testid="NotFoundPage"
      footer={footerByLang}
      footerNavigationLoggedIn={footerNavigationLoggedInByLang}
      header={headerByLang}
      headerNavigationLoggedIn={headerNavigationLoggedInByLang}
      headerAccount={headerAccountByLang}
      privatePage={false}
      seo={notFoundBlockByLang?.seo}
    >
      {notFoundBlockByLang?.locale ? (
        <NotFoundBlock
          text={notFoundBlockByLang.text}
          header={notFoundBlockByLang.header}
          link={notFoundBlockByLang.link}
        />
      ) : null}
    </Layout>
  );
};

export default NotFoundPage;
