import React, { FC } from 'react';
import { Typography } from '@design-system/typography';
import { linkMapper } from 'mappers/link';
import { typographyMapper } from 'mappers/typography';

import TMPLinkWrap from 'components/TMPLinkWrap';

import { INotFoundBlockProps } from './models';

import './NotFoundBlock.scss';

const NotFoundBlock: FC<INotFoundBlockProps> = ({ link, header, text }) => (
  <div data-testid="NotFoundBlock" className="not-found-block">
    <Typography {...typographyMapper(header)} className="not-found-block__title" />
    <Typography {...typographyMapper(text)} className="not-found-block__text" />
    <TMPLinkWrap {...linkMapper(link)} className="not-found-block__link" />
  </div>
);
export default NotFoundBlock;
